import React from "react"
import styled from "styled-components"
import { Container } from "react-bootstrap"

import { Box, Section } from "../../components/Core"

import dachser from "../../assets/image/svg/logos/Dachser-logo.svg"
import dt from "../../assets/image/svg/logos/Deutsche_Telekom-Logo.svg"
import telefonica from "../../assets/image/svg/logos/Telefónica_Logo.svg"
import ge from "../../assets/image/svg/logos/General_Electric_logo.svg"
import innoscripta from "../../assets/image/svg/logos/Innoscripta.svg"
import tup from "../../assets/image/svg/logos/TuP.png"
import stadtwerke from "../../assets/image/svg/logos/stadtwerke-karlsruhe-vector-logo.svg"
import luithle from "../../assets/image/svg/logos/Luithle_Luithle.svg"
import conitas from "../../assets/image/svg/logos/CONITAS.svg"
import ihk from "../../assets/image/svg/logos/ihk-logo.svg"
import { Trans } from "@lingui/macro"

const Brand = styled(Box)`
  width: 150px;
  padding-bottom: 38px;
  opacity: 0.7;
  transition: all 0.3s ease-out;
  &:hover {
    opacity: 1;
  }
`

const Logo = styled.img`
  max-height: 50px;
  margin-left: auto;
  margin-right: auto;
  display: block;
`

const Clients = () => (
  <Section className="pt-0 pb-2">
    <Container css={{ paddingBottom: 50 }}>
      <h2 className="text-center">
        <Trans>Unternehmen aller Branchen vertrauen auf AMAI</Trans>
      </h2>
    </Container>
    <Container>
      <Box
        className="d-flex justify-content-center justify-content-lg-around
         align-items-center flex-wrap"
        mx="-32px">
        <Brand py={3} mx={3}>
          <Logo src={dachser} alt="" className="img-fluid" />
        </Brand>
        <Brand py={3} mx={3}>
          <Logo src={dt} alt="" className="img-fluid" />
        </Brand>
        <Brand py={3} mx={3}>
          <Logo src={ge} alt="" className="img-fluid" />
        </Brand>
        <Brand py={3} mx={3}>
          <Logo src={innoscripta} alt="" className="img-fluid" />
        </Brand>
        <Brand py={3} mx={3}>
          <Logo src={telefonica} alt="" className="img-fluid" />
        </Brand>
        <Brand py={3} mx={3}>
          <Logo src={tup} alt="" className="img-fluid" />
        </Brand>
        <Brand py={3} mx={3}>
          <Logo src={stadtwerke} alt="" className="img-fluid" />
        </Brand>
        <Brand py={3} mx={3}>
          <Logo src={luithle} alt="" className="img-fluid" />
        </Brand>
        <Brand py={3} mx={3}>
          <Logo src={conitas} alt="" className="img-fluid" />
        </Brand>
        <Brand py={3} mx={3}>
          <Logo src={ihk} alt="" className="img-fluid" />
        </Brand>
      </Box>
    </Container>
  </Section>
)

export default Clients
