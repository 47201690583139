import React from "react"
import { Container, Row, Col } from "react-bootstrap"

import { Section } from "../../components/Core"
import { t } from "@lingui/macro"

const Process = () => (
  <Section>
    <Container className="d-none d-md-block">
      <Row className="mb-4">
        <Col sm="9">
          <h2 variant="hero">{t`Ergebnisse in Wochen, nicht Jahren.`}</h2>
        </Col>
      </Row>
      <Row>
        <div
          className="timeline d-flex w-100 pl-2 pr-2"
          css={{ height: "120px" }}>
          <div className="timeline-box" css={{ flex: "0 0 7%" }}>
            <div
              className="h-75 p-2 pt-2 text-light rounded-left font-weight-light"
              css={{ background: "#163D5A" }}>{t`AI Discovery Workshop`}</div>
            <div className="h-25 p-1 font-weight-light">2 {t`Stunden`}</div>
          </div>
          <div className="timeline-box" css={{ flex: "0 0 11%" }}>
            <div
              className="h-75 p-2 pt-2 text-light font-weight-light"
              css={{
                background: "#206287",
              }}>{t`Proof of Concept Entwicklung`}</div>
            <div className="h-25 p-1 font-weight-light">3-4 {t`Wochen`}</div>
          </div>
          <div className="timeline-box" css={{ flex: "0 0 20%" }}>
            <div
              className="h-75 p-2 pt-2 pl-3 text-light font-weight-light"
              css={{
                background: "#2B83B6",
              }}>{t`Erproben und Verbessern der Lösung`}</div>
            <div className="h-25 p-1 pl-3 font-weight-light">
              6-12 {t`Wochen`}
            </div>
          </div>
          <div className="timeline-box" css={{ flex: "1 0 auto" }}>
            <div
              className="h-75 p-2 pt-2 pl-3 text-light rounded-right font-weight-light"
              css={{
                background: "#46A0D4",
              }}>{t`KI Anwendung + Deployment in Produktion`}</div>
            <div className="h-25 p-1 pl-3 font-weight-light">
              3-6 {t`Monate`}
            </div>
          </div>
        </div>
      </Row>
    </Container>

    <Container className="d-block d-md-none">
      <Row className="mb-5 justify-content-center">
        <Col xs="11">
          <h2 variant="hero">{t`Ergebnisse in Wochen, nicht Jahren.`}</h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <div className="timeline" css={{ height: "450px" }}>
          <Row
            className="timeline-box w-100 flex-row justify-content-center"
            css={{ height: "7%" }}>
            <Col
              className="font-weight-light text-light offset-1 pt-1"
              xs="4"
              css={{ background: "#163D5A", width: "20%", height: "100%" }}>
              2 {t`Stunden`}
            </Col>
            <Col xs="7" className="pr-0">{t`AI Discovery Workshop`}</Col>
          </Row>
          <Row
            className="timeline-box w-100 flex-row justify-content-center"
            css={{ height: "11%" }}>
            <Col
              className="font-weight-light text-light pt-1 offset-1"
              xs="4"
              css={{ background: "#206287", width: "20%", height: "100%" }}>
              3-4 {t`Wochen`}
            </Col>
            <Col xs="7">{t`Proof of Concept Entwicklung`}</Col>
          </Row>
          <Row
            className="timeline-box w-100 flex-row justify-content-center"
            css={{ height: "30%" }}>
            <Col
              className="font-weight-light text-light pt-2 offset-1"
              xs="4"
              css={{ background: "#2B83B6", width: "20%", height: "100%" }}>
              6-12 {t`Wochen`}
            </Col>
            <Col
              xs="7"
              className="pt-2">{t`Erproben und Verbessern der Lösung`}</Col>
          </Row>
          <Row
            className="timeline-box w-100 flex-row justify-content-center"
            css={{ height: "52%" }}>
            <Col
              className="font-weight-light text-light pt-3 offset-1"
              xs="4"
              css={{ background: "#46A0D4", width: "20%", height: "100%" }}>
              3-6 {t`Monate`}
            </Col>
            <Col xs="7" className="pt-3">{t`KI Anwendung + Deployment in Produktion`}</Col>
          </Row>
        </div>
      </Row>
    </Container>
  </Section>
)

export default Process
