import React from "react"
import { Container, Row, Col } from "react-bootstrap"

import { Title, Section, Text } from "../../components/Core"
import { t, Trans } from "@lingui/macro"
import { StaticImage } from "gatsby-plugin-image"
import Clients from "../landing/ClientsSmall"
import Process from "./Process"

const Content = () => (
  <>
    <Section className="pb-0">
      <Container>
        <Row className="justify-content-center py-4 mt-5">
          <Col lg="6">
            <Title variant="hero">AI made in Germany.</Title>
          </Col>
          <Col lg="6" className="pl-lg-5">
            <Text>
              {t`Wir von AMAI haben uns auf die Beratung und technische Unterstützung europäischer Unternehmen bei der Digitalisierung und Integration von künstlicher Intelligenz in Produkte und Dienstleistungen spezialisiert. Unser Fokus liegt dabei auf Data-Science- und Machine-Learning-Themen, insbesondere Computer Vision und Natural Language Processing.`}
            </Text>
          </Col>
        </Row>
      </Container>

      <Container fluid="sm" className="py-4">
        <Row className="mt-md-3">
          <Col className="p-0">
            <StaticImage
              src="./industry-banking.jpg"
              class="img-fluid"
              style={{
                height: 230,
                filter: "grayscale(100%)",
                "object-fit": "cover",
              }}
            />
          </Col>
          <Col className="p-0 mx-2 mx-md-3">
            <StaticImage
              src="./industry-logistic.jpg"
              class="img-fluid"
              style={{
                height: 230,
                filter: "grayscale(100%)",
                "object-fit": "cover",
              }}
            />
          </Col>
          <Col className="p-0">
            <StaticImage
              src="./industry-solar.jpg"
              class="img-fluid"
              style={{
                height: 230,
                filter: "grayscale(100%)",
                "object-fit": "cover",
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col className="mt-3" sm="8" xs="12">
            <h2>
              <Trans>Maß​geschneiderte KI-Lösungen</Trans>{" "}
              <Trans>für jede Branche</Trans>
            </h2>
          </Col>
          <Col
            className="mt-3 font-weight-light text-left text-sm-right"
            sm="4"
            xs="12">
            <span style={{ color: "#206287", fontWeight: "normal" }}>AMAI</span>{" "}
            AI Experts
          </Col>
        </Row>
      </Container>
    </Section>
    <Section>
      <Container>
        <Row className="justify-content-center pt-md-3 mt-3 mt-sm-3">
          <Col lg="8">
            <Text>
              {t`Wir verfügen über langjährige Praxiserfahrung bei der Umsetzung solcher Projekte im Auftrag verschiedener Kunden (auch großer Konzerne). Unser Leistungsspektrum umfasst die Entwicklung konkreter Prototypen und Softwareprodukte, die Integration von KI-Ansätzen und -Services, die Beratung bei der Projektumsetzung in diesem Bereich sowie die strategische Managementberatung, um das Potenzial von KI im jeweiligen Unternehmen aufzuzeigen.`}
            </Text>
          </Col>
        </Row>
      </Container>
    </Section>

    <Process />

    <Section bg="#f7f7fb">
      <Container>
        <Row className="justify-content-center pt-md-3 mt-4 mt-sm-3">
          <Col lg="8">
            <Text>
              {t`Unser Unternehmen selbst ist jung, die Gründer sind jedoch erfahrene Datenwissenschaftler, Ingenieure und Manager. Wir verfügen über umfangreiches Know-how bezüglich allgemeiner Machine-Learning-Algorithmen, Tools, Entwicklung und Best Practices, insbesondere in (aber nicht nur) den Bereichen Natural Language Understanding (NLU) und Computer Vision.`}
            </Text>
          </Col>
        </Row>
      </Container>
    </Section>
    <Section bg="#f7f7fb" className="pt-3">
      <Container>
        <Row className="justify-content-center">
          <Col lg="8">
            <Clients />
          </Col>
        </Row>
      </Container>
    </Section>
  </>
)

export default Content
