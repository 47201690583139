import React from "react"


import PageWrapper from "../components/PageWrapper"
import SEO from "../components/SEO"
import Content from "../sections/about/Content";
import { t } from "@lingui/macro"

const About = ({ location }) => {
  return (
    <>
      <PageWrapper headerLight>
        <SEO
          pathname={location.pathname}
          title={t`Über Uns`}
          description={t`Die AMAI GmbH hilft Ihnen, Künstliche Intelligenz in Ihre Prozesse und Produkte zu integrieren. Auf dem Gebiet des Machine Learning, Deep Learning sind wir Experten. Wir beraten Sie und realisieren Ihr KI Projekt.`}
        />

        <Content />

        {/*}
                <Hero />
        <Benefits />
        <Feature />
        <Roles />
        */}
      </PageWrapper>
    </>
  )
}
export default About
